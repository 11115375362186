<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-row>
                  <v-col>
                    <CommonAutocomplete
                      v-model="supplier"
                      :label="$t('supplier.supplier')"
                      :placeholder="''"
                      url="/supplier/supplier/"
                      :item_text="nameField"
                      item_value="id"
                      :query_field="nameFieldFilter"
                      :extra_condition="{
                        enable: true
                      }"
                      :required="true"
                    ></CommonAutocomplete>
                  </v-col>
                  <v-col>
                    <CommonAutocomplete
                      v-model="customer"
                      :label="$t('customer.customer')"
                      :placeholder="''"
                      url="/customer/customer/"
                      :item_text="nameField"
                      item_value="id"
                      :query_field="nameFieldFilter"
                      :extra_condition="{
                        enable: true
                      }"
                      :required="true"
                    ></CommonAutocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.accreditation_number"
                      :label="$t('commodity.accreditation-number')"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.china_apply_custom_number"
                      :label="$t('commodity.number')"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <CommonAutocomplete
                      v-model="customs_type"
                      :label="$t('commodity.customs-type')"
                      url="/commodity/customs-type/"
                      :item_text="nameField"
                      item_value="id"
                      :query_field="nameFieldFilter"
                      :required="true"
                      :extra_condition="{
                        enable: true
                      }"
                    ></CommonAutocomplete>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.customs_type_str"
                      :label="$t('commodity.customs-type')"
                      :disabled="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.amount"
                      :label="$t('container.total_amount')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.netWeight"
                      :label="$t('container.total_net_weight')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.unit_price"
                      :label="$t('container-box.customs-price')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.total_price"
                      :label="$t('container-box.total-customs-price')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
import CommonAutocomplete from "../filters/CommonAutocomplete";
export default {
  props: {

  },
  data: (vm) => ({
    topic: vm.$i18n.t("container.manual-compare"),
    supplier: null,
    customer: null,
    customs_type: null,
  }),
  computed: {
    allowSave() {
      return !(
        this.supplier &&
        this.customer &&
        this.customs_type
      )
    },
  },
  mixins: [BaseDialog],
  components: {
    CommonAutocomplete,
  },
  methods: {
    save(){
      console.log('save',this.item)
      let new_container_box = {
        supplier: this.supplier,
        customer: this.customer,
        accreditation_number: this.item.accreditation_number,
        commodity_number: this.item.china_apply_custom_number,
        customs_type: this.customs_type,
        amount: this.item.amount,
        net_weight: this.item.netWeight,
        unit_price: this.item.unit_price,
        total_price: this.item.total_price,
        declaration_item: this.item.id,
      }
      console.log('new_container_box',new_container_box)
      this.$emit('onSave',new_container_box)
    },
  },
  watch: {
    item(val, oldVal){
      console.log('item',val)
      this.supplier = null
      this.customer = null
      this.customs_type = null
    }
  },
}
</script>