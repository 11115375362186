<template>
  <v-container>
    <CustomDeliverFeeAfterPdfDialogVue
      :show="showDialog"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="submitRepairItem"
    ></CustomDeliverFeeAfterPdfDialogVue>
    <v-row>
      <v-col>
        <v-btn
          icon
          class="hidden-xs-only"
          @click="
            $router.push({
              name: 'CustomDeliverFeeAfterList',
              params: {}
            })
          "
        >
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container.repair-box')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="repairItem(item)"
              >mdi-plus</v-icon>
            </template>
            <span>{{ $t("container.repair-box") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import CustomDeliverFeeAfterPdfDialogVue from '../dialogs/CustomDeliverFeeAfterPdfDialog';
export default {
  data: (vm) => ({
    url: "/container/declaration_item/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.name"),
        value: "accreditation_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.number"),
        value: "china_apply_custom_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container-box.apply-custom-price"),
        value: "unit_price",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_amount"),
        value: "amount",
      },
      // {
      //   sortable: true,
      //   text: vm.$i18n.t("netWeight"),
      //   value: "netWeight",
      // },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
    container_id: null,
  }),
  mixins: [BaseTable],
  components: {
    CustomDeliverFeeAfterPdfDialogVue
  },
  methods: {
    repairItem(item){
      console.log('repairItem',item)
      this.dialogItem = item
      this.showDialog = true;
    },
    submitRepairItem(item){
      this.showDialog = false;
      console.log('submitRepairItem',this.container_id,item)
      this.postApi(
        item,
        "/container/container/"+this.container_id+"/repairContainerBox/"
      )
    },
  },
  mounted() {
    let declaration = this.$route.query.declaration;
    let container_id = this.$route.query.container_id
    this.getParams = {
      declaration: declaration,
      status: 1
    };
    this.container_id = container_id
  },
}
</script>