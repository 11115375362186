var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('CustomDeliverFeeAfterPdfDialogVue',{attrs:{"show":_vm.showDialog,"item":_vm.dialogItem},on:{"onClose":_vm.onCloseEvent,"onSave":_vm.submitRepairItem}}),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
            name: 'CustomDeliverFeeAfterList',
            params: {}
          })}}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1)],1)],1),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('container.repair-box')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.repairItem(item)}}},on),[_vm._v("mdi-plus")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("container.repair-box")))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }